<template>
    <div class="page-layout blank">
        <div class="pb-10 h-100" style="height:100% !important">
            <div class="d-flex flex-row flex-wrap justify-content-between align-items-center">
                <div class="breadcrumbactive">
                    <div>
                        <span class="pointer" @click="goToCompanyList">Company</span>
                        <span>Employee</span>
                        <a>List View</a>
                    </div>
                </div>
                <div>
                    <div class="d-flex align-items-center flex-row flex-wrap">
                        <!-- <button type="button" class="btn btn-smm btn-new-secondary pl-3" style="border: 0 !important;border-radius: 6px !important;">
                            <img src="/static/images/filter.svg" />
                            Filter
                        </button> -->
                        <!-- <button type="button" class="btn btn-smm btn-danger ml-3" style="border-radius: 6px !important;"
                            @click="openTerminateEmployeeModal()">
                            <i class="icon icon-minus fs-12 text-white"></i>
                            Delete
                        </button> -->
                        <div class="d-flex align-items-center w-100" style="width: 290px !important;position: relative">
                            <i class="icon icon-magnify fs-15 pl-2" style="position: absolute; left: 2px;"></i>
                            <i v-if="search_key !=''" class="icon icon-close fs-20" style="position: absolute; right: 10px;color: #707070 !important;cursor: pointer;" @click="clearSearch"></i>
                            <input type="text" class="search-input-field" v-model="search_key" @input="getEmployeesDataOninput" placeholder="Search by Name, Employee ID, E-mail" />
                        </div>
                        <button v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'super_admin'" type="button" class="btn btn-smm btn-success text-white ml-3"
                            style="border-radius: 6px !important;" @click="redirectCreateEmployee()">
                            + Add New Employee
                        </button>
                        <button v-else-if="loggedInUser.user_role == 'admin' && (loggedInUser.company_id == $route.params.company_id)" type="button" class="btn btn-smm btn-success text-white ml-3"
                            style="border-radius: 6px !important;" @click="redirectCreateEmployee()">
                            + Add New Employee
                        </button>
                    </div>
                </div>
            </div>
            


            <div class="w-100 bg-white mb-20 mb-md-0"> 
                <div class="d-flex align-items-center">
                    <!-- <div class="d-flex align-items-center">
                        <div class="header-slant-tab">
                            <span class="text-secondary fs-14">List View</span>
                        </div>
                        <div class="header-slant-tab-border"></div>
                    </div> -->
                    <!-- <div class="d-flex align-items-center w-100 mb-3" style="position: relative;;">
                        <i class="icon icon-magnify fs-15" style="position: absolute; left: 10px;"></i>
                        <input type="text" class="search-input-field" v-model="search_key" @input="getEmployeesDataOninput" placeholder="Search by Name, Employee ID, E-mail" />
                    </div> -->
                    <!-- <div class="d-flex justify-content-center ml-2 mb-3"
                        style="background: rgb(255, 255, 255); border-radius: 20px; min-width: 150px;height: 2.9em;">
                        <div class="d-flex justify-content-center align-items-center pointer" style="padding: 0 10px;"
                            role="button">
                            <img src="/static/images/ic_arrow2.png" style="margin-right:8px;" alt="">
                            All Employees
                        </div>
                    </div> -->
                    <!-- <div class="d-flex justify-content-center mb-3 ml-2"
                        style="background: rgb(255, 255, 255); border-radius: 20px; width: 220px; height: 2.9em;min-width:95px;">
                        <div class="d-flex justify-content-center align-items-center pointer pointer-events-none"
                            style="padding: 0 10px; border-right: 0.5px solid rgb(204, 227, 252);">
                            <img src="/static/images/grid_new.svg" width="18">
                        </div>
                        <div class="d-flex justify-content-center align-items-center pointer"
                            style="padding: 0 10px; border-left: 0.5px solid rgb(204, 227, 252); opacity: 0.5;">
                            <img src="/static/images/filter_bars_new.svg" width="18">
                        </div>
                    </div> -->
                </div>
                <div class="product-block" style="background-color:#fff;border-top-right-radius:15px;border-bottom-right-radius:15px;border-bottom-left-radius:15px;">
                    <div class="create-company-container w-100">
                        <div class="mb-4">
                            <div class="row" v-if="ajax_call_in_progress">
                                <div class="col-lg-12 py-5 px-3 mt-3">
                                    <div class="flex-container">
                                        <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="employees_data.length == 0 && !ajax_call_in_progress" class="row flex-wrap align-items-center">
                                <div class="col-lg-12 col-md-12 col-sm-12 pt-10">
                                    <p class="text-dark fs-14 text-center">No Data Found</p>
                                </div>
                            </div>
                            <div v-else class="row" style="flex-wrap:wrap;">
                                <div v-for="(employee, index) in employees_data" :key="index" class="col-lg-4 col-md-4 col-sm-12 mt-12 d-flex justify-content-center">
                                    <div class="w-280px w-100 h-100"
                                        style="box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);border-bottom-left-radius: 5px;border-bottom-right-radius:5px">
                                        <div class="px-2"
                                            style="background: url('/static/images/Rectangle 19382@3x.png') no-repeat;background-size:100% 100%;height:300px;">
                                            <div v-if="employee.user_access == 'owner'" class="dotted_line">
                                                <span class="" style="border-right:4px dotted #fff; opacity: 0; visibility: hidden;" role="button"></span>
                                            </div>
                                            <div v-else>
                                                <div v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'super_admin' || loggedInUser.user_role == 'admin'" class="dotted_line">
                                                    <div class="d-flex">
                                                        <div v-if="employee.status == 'suspended'" class="pointer" style="margin-top: -3px;" @click="showRevokePopup(employee, index)">
                                                            <el-tooltip class="item" effect="dark" content="Revoke" placement="top">
                                                                <img src="/static/images/white-suspend.png" alt="" width="27px">
                                                            </el-tooltip>
                                                        </div>
                                                        <div v-if="employee.status == 'active'">
                                                            <el-dropdown v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super_admin' || loggedInUser.user_role == 'super admin'" trigger="click">
                                                                <el-button type="primary">
                                                                    <span class="pointer" style="border-right:4px dotted #fff;" role="button"></span>
                                                                </el-button>
                                                                <el-dropdown-menu slot="dropdown">
                                                                    <el-dropdown-item><span class="d-block" v-if="employee.user_access == 'employee' || employee.user_access == 'admin'" @click="assignToDifferent('super_admin', employee.id)">Super Admin</span></el-dropdown-item>
                                                                    <el-dropdown-item><span class="d-block" v-if="employee.user_access != 'admin'" @click="assignToDifferent('admin', employee.id)">Admin</span></el-dropdown-item>
                                                                    <el-dropdown-item><span class="d-block" v-if="employee.user_access != 'employee'" @click="assignToDifferent('employee', employee.id)">User</span></el-dropdown-item>
                                                                    <el-dropdown-item><span class="d-block" style="color: red;" @click="showSuspendPopup(employee, index)">Suspend</span></el-dropdown-item>
                                                                </el-dropdown-menu>
                                                            </el-dropdown>
                                                            <el-dropdown v-else-if="loggedInUser.user_role == 'admin' && (loggedInUser.company_id == $route.params.company_id) && (employee.user_access == 'admin' || employee.user_access == 'employee')" trigger="click">
                                                                <el-button type="primary">
                                                                    <span class="pointer" style="border-right:4px dotted #fff;" role="button"></span>
                                                                </el-button>
                                                                <el-dropdown-menu slot="dropdown">
                                                                    <el-dropdown-item><span class="d-block" v-if="employee.user_access == 'employee'" @click="assignToDifferent('admin', employee.id)">Admin</span></el-dropdown-item>
                                                                    <el-dropdown-item><span class="d-block" v-if="employee.user_access != 'employee'" @click="assignToDifferent('employee', employee.id)">User</span></el-dropdown-item>
                                                                    <el-dropdown-item><span class="d-block" style="color: red;" @click="showSuspendPopup(employee, index)">Suspend</span></el-dropdown-item>
                                                                </el-dropdown-menu>
                                                            </el-dropdown>
                                                            <span v-else-if="loggedInUser.user_role == 'admin' && (loggedInUser.company_id == $route.params.company_id) && (employee.user_access == 'super_admin' || employee.user_access == 'super admin')" style="border-right:4px dotted #fff; opacity: 0; visibility: hidden;" role="button"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-else-if="loggedInUser.user_role == 'employee'" class="dotted_line">
                                                    <span class="" style="border-right:4px dotted #fff; opacity: 0; visibility: hidden;" role="button"></span>
                                                </div>
                                            </div>
                                            <div class="d-flex flex-column w-100 user_details" style="cursor: no-drop;" v-if="employee.status == 'suspended'">
                                                <div class="ml-auto mr-auto">
                                                    <img :class="{'profile_pic_super_admin': employee.user_access == 'super admin' || 'Super Admin', 'profile_pic_admin': employee.user_access == 'admin' || 'Admin', 'profile_pic_employee': employee.user_access == 'employee' || 'Employee'}"
                                                        :src="employee.profile_pic || 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png'" width="135" height="135" alt="">
                                                </div>
                                                <span class="text-white text-center fw-600 fs-16 px-3" :title="employee.full_name" style="margin-top:2.2rem;overflow-wrap: break-word;height: 40px;overflow: hidden;text-overflow: ellipsis;display: block;white-space: nowrap;">{{employee.full_name}}</span>
                                                <button :disabled="employee.status == 'suspended'" style="margin:0.5rem 15px 0px;background-color: #00448b !important;" class="text-capitalize fs-14 fw-500 disabled"
                                                    :class="{'profile_button_super_admin': employee.user_access == 'super admin' || 'Super Admin', 'profile_button_admin': employee.user_access == 'admin' || 'Admin', 'profile_button_employee': employee.user_access == 'employee' || 'Employee'}">
                                                    {{employee.user_access.replace("_"," ")}}
                                                    </button>
                                            </div>
                                            <div v-else class="d-flex flex-column w-100 user_details pointer" @click="goToEmployeeView(employee.id, employee.user_access)">
                                                <div class="ml-auto mr-auto">
                                                    <img :class="{'profile_pic_super_admin': employee.user_access == 'super admin' || 'Super Admin', 'profile_pic_admin': employee.user_access == 'admin' || 'Admin', 'profile_pic_employee': employee.user_access == 'employee' || 'Employee'}"
                                                        :src="employee.profile_pic || 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png'" width="135" height="135" alt="">
                                                </div>
                                                <span class="text-white text-center fw-600 fs-16 px-3" :title="employee.full_name" style="margin-top:2.2rem;overflow-wrap: break-word;height: 40px;overflow: hidden;text-overflow: ellipsis;display: block;white-space: nowrap;">{{employee.full_name}}</span>
                                                <button :disabled="employee.status == 'suspended'" style="margin:0.5rem 15px 0px;background-color: #00448b !important;" class="text-capitalize fs-14 fw-500 disabled"
                                                    :class="{'profile_button_super_admin': employee.user_access == 'super admin' || 'Super Admin', 'profile_button_admin': employee.user_access == 'admin' || 'Admin', 'profile_button_employee': employee.user_access == 'employee' || 'Employee'}">
                                                    {{employee.user_access.replace("_"," ")}}
                                                    </button>
                                            </div>
                                        </div>
                                        <div class="px-3">
                                            <div class="e-tab">
                                                <span class="py-2 fs-12">Email</span>
                                                <span class="py-2 fs-12 email-ellipses">{{employee.email}}</span>
                                            </div>
                                            <div class="e-tab" style="border-top:1px solid #000;">
                                                <span class="py-2 fs-12">Tel</span>
                                                <span class="py-2 fs-12">{{employee.phone_number.phone_number}}</span>
                                            </div>
                                            <div v-if="employee.status == 'active'">
                                                <div v-if="loggedInUser.user_role == 'owner'">
                                                    <div v-if="employee.user_access == 'super admin' || employee.user_access == 'super_admin' || employee.user_access == 'admin' || employee.user_access == 'employee'" class="text-center" style="padding:20px 25px 25px 25px">
                                                        <button class="btn btn-danger w-100 fw-500 fs-14" @click="openTerminateEmployeeModal(employee.id, employee.full_name)">Terminate</button>
                                                    </div>
                                                </div>
                                                <div v-else-if="loggedInUser.user_role == 'super_admin' || loggedInUser.user_role == 'super admin'">
                                                    <div v-if="employee.user_access == 'admin' || employee.user_access == 'employee'" class="text-center" style="padding:20px 25px 25px 25px">
                                                        <button class="btn btn-danger w-100 fw-500 fs-14" @click="openTerminateEmployeeModal(employee.id, employee.full_name)">Terminate</button>
                                                    </div>
                                                    <div v-else-if="loggedInUser.user_role == 'admin' && (loggedInUser.company_id == $route.params.company_id)" class="text-center" style="padding:20px 25px 25px 25px">
                                                        <button class="btn btn-danger w-100 fw-500 fs-14" @click="openTerminateEmployeeModal(employee.id, employee.full_name)">Terminate</button>
                                                    </div>
                                                </div>
                                                <div v-if="loggedInUser.user_role == 'admin'">
                                                    <div v-if="employee.user_access == 'employee'" class="text-center" style="padding:20px 25px 25px 25px">
                                                        <button class="btn btn-danger w-100 fw-500 fs-14" @click="openTerminateEmployeeModal(employee.id, employee.full_name)">Terminate</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="employee.status == 'suspended'" class="text-center" style="padding:20px 25px 25px 25px">
                                                <button class="btn-danger w-100 fw-500 fs-14 disabled" :disabled="employee.status == 'suspended'" style="height: 3.6rem;border-radius: 2px;">Suspended</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="!ajax_call_in_progress" class="d-flex product-block-container pb-3 justify-content-end" style="border-radius: 15px;">
                    <div class="d-flex justify-content-end align-items-center bg-white py-2 px-4" v-if="employees_data.length > 0"
                            style="border-bottom-left-radius: 5px; border-bottom-right-radius: 5px">
                        <multiselect class="diginew-multiselect"
                            :options="pageLimit" :close-on-select="true" v-model.number="limit" 
                            :open-direction="employees_data.length > 0 ? 'top' : 'bottom'" :show-labels="false" placeholder=""
                            style="width: 4.0em !important;padding-right: 10px!important;padding-top: 10px !important;"></multiselect>
                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/down-arrow-grey.svg"
                            style="margin-left: -20px;">
                        <span class="page-info pr-2 pl-6 fw-500"
                            style="height: 2.4rem;line-height: 2.4rem;color: #303031;font-size: 14px;">Per page</span>
                        <button @click="firstPage()" :class="{'not-allowed' : skip == 0}" :disabled="skip == 0" class="mt-1 pointer pagination-list">
                            <el-tooltip class="item" effect="dark" content="First Page" placement="top">
                                <i class="icon-chevron-double-left"></i>
                            </el-tooltip>
                        </button>
                        <button @click="previousPage()" :class="{'not-allowed' : skip == 0}" :disabled="skip == 0" class="pagination-list mt-1 ml-2 pointer">
                            <el-tooltip class="item" effect="dark" content="Previous Page" placement="top">
                                <i class="icon icon-chevron-left"></i>
                            </el-tooltip>
                        </button>
                        <span class="px-2 fw-500 fs-14 mt-1">Showing {{getSkipCount}} -
                            {{total>skip+limit?skip+limit:total}} of {{total}}</span>
                        <button @click="nextPage()" :disabled="skip_temp >= total" :class="{'not-allowed' : skip_temp >= total }"
                            class="pagination-list mt-1 mr-2 pointer">
                            <el-tooltip class="item" effect="dark" content="Next Page" placement="top">
                                <i class="icon icon-chevron-right"></i>
                            </el-tooltip>
                        </button>
                        <button @click="lastPage()" class="mt-1 pointer pagination-list" :disabled="temp_total == total" :class="{'not-allowed' : temp_total == total}">
                            <el-tooltip class="item" effect="dark" content="Last Page" placement="top">
                                <i class="icon-chevron-double-right"></i>
                            </el-tooltip>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <suspend-pop-up v-if="show_suspend_popup" modal_name="suspendPopup" :type="suspend_revoke" :full_name="suspend_data.full_name" :suspend_id="suspend_data.id ? suspend_data.id : ''" :index="suspend_index" @suspendedEmploy="suspendedEmploy" @cancelSuspend="cancelSuspend"></suspend-pop-up>
        <terminate-employee-modal :employee_id="employee_id" :employee_name="employee_name" @hide-terminate-employee="CloseTerminateEmployee" @terminate-employee-success="terminateEmployeeSuccess" v-if="openterminateemployee"
            modal_name="Terminate_Employee"></terminate-employee-modal>
        <terminate-employee-warning-modal :message="terminate_error_message" @hide-terminate-employee-warning="CloseTerminateEmployeeWarning" v-if="open_terminate_employee_warning"
            modal_name="Terminate_Employee_Warning"></terminate-employee-warning-modal>
        <sweet-modal ref="warning_modal" overlay-theme="dark" icon="warning">{{warning_msg}}</sweet-modal>
        <sweet-modal ref="success_modal" overlay-theme="dark" icon="success">{{success_msg}}</sweet-modal>
    </div>
</template>

<script>
    import companies from '../mixins/companies'
    import {HalfCircleSpinner} from 'epic-spinners';
    import { SweetModal } from 'sweet-modal-vue';
    import TerminateEmployeeModal from './TerminateEmployeeModal'
    import TerminateEmployeeWarningModal from './TerminateEmployeeWarningModal'
    import globals from '../globals';
    import suspendPopUp from "./suspendPopup";
    export default ({
        mixins: [companies],
        data() {
            return {
                filter_employee: '',
                openterminateemployee: false,
                skip: 0,
                limit: 10,
                search_key: '',
                warning_msg: '',
                success_msg: '',
                count: 0,
                start: 0,
                end: 0,
                total: 0,
                skip_temp:0,
                temp_total:0,
                pageLimit: [],
                employees_data: [],
                ajax_call_in_progress: false,
                show_list_epmloyee: false,
                comp_data: {
                    company_name: '',
                    logo: '',
                    cover_image: ''
                },
                employee_id: '',
                employee_name: '',
                toShow: '',
                terminate_error_message: '',
                open_terminate_employee_warning: false,
                show_suspend_popup: false,
                suspend_data: null,
                suspend_index: 0,
                status: ''
            }
        },
        methods: {
            showRevokePopup(data, index) {
                this.suspend_data = data;
                this.suspend_revoke = "Revoke"
                this.status = 'active'
                this.suspend_index = index
                this.show_suspend_popup = true
                setTimeout(() => {
                    this.$modal.show("suspendPopup");
                }, 500);
            },
            cancelSuspend() {
                this.show_suspend_popup = false
                this.suspend_revoke = ""
                setTimeout(() => {
                    this.$modal.hide("suspendPopup");
                }, 500);
            },
            showSuspendPopup(data, index) {
                this.suspend_data = data;
                this.suspend_revoke = "Suspend"
                this.status = 'suspended'
                this.suspend_index = index
                this.show_suspend_popup = true
                setTimeout(() => {
                    this.$modal.show("suspendPopup");
                }, 500);
            },
            async suspendedEmploy(data) {
                if(data.id.length) {
                    try {
                        let params = {
                            "status": this.status, // "suspended" or "active"
                            "employee_company_id": data.id
                        }
                        let response = await this.suspendEmployeeById(params);
                        if(response.status_id == 1) {
                        this.success_msg = response.message;
                        this.$refs.success_modal.open();
                        setTimeout(() => {
                            this.$refs.success_modal.close();
                            this.getEmployeesData()
                            // this.GetUserDetails(this.$route.params.id);
                        }, 3000);
                        }
                    }
                    catch(err) {
                        // console.log(err);
                        this.warning_msg = err;
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                } 
                else {
                    // this.address.splice(data.index,1);
                }
                this.show_suspend_popup = false
                setTimeout(() => {
                this.$modal.hide("suspendPopup");
                }, 500);
            },
            clearSearch(){
                this.search_key = ""
                this.getEmployeesData();
            },
            eraseFilter() {
                this.filter_employee = ""
            },
            goToCompanyList() {
                this.$router.push('/company/view/cmpid/' + this.$route.params.company_id);
            },
            goToParticularPage(type) {
                jQuery('.bigcontainer').animate({scrollTop:0},0);
                if(type == 'company_settings') {
                    this.$router.push('/company/settings/cmpid/' + this.$route.params.company_id);
                } else if(type == 'outlets') {
                    this.toShow = 'outlets';
                    this.$router.push({ path: "/company/view/cmpid/"+this.$route.params.company_id, query: {outlet_view: 'view'} })
                    this.outletsinfo = true;
                } else if (type == 'bankdetails') {
                    this.toShow = 'bankdetails';
                    this.$router.push({ path: "/company/view/cmpid/"+this.$route.params.company_id, query: {bankdetails_view: 'view'} })
                    this.bankdetailsinfo = true;
                } else if (type == 'company_info') {
                    this.toShow = 'company_info';
                    this.$router.push('/company/view/cmpid/' + this.$route.params.company_id);
                    this.companyinfo = true;
                } else if (type == 'business_unit') {
                    this.$router.push('/businessunit/listview/cmpid/' + this.$route.params.company_id);
                } else if (type == 'department_unit') {
                    this.$router.push('/departmentunit/listview/cmpid/' + this.$route.params.company_id);
                } else if (type == 'employees') {
                    this.toShow = 'employees';
                    this.$router.push('/employee/listemployee/cmpid/' + this.$route.params.company_id);
                } else if (type == 'product_access') {
                    this.$router.push('/product/view/cmpid/' + this.$route.params.company_id);
                }
                else if (type == 'consultant'){
                    this.$router.push('/consultants/listview/cmpid/'+this.$route.params.company_id)
                }
            },
            openTerminateEmployeeModal(id, name) {
                this.employee_id = id;
                this.employee_name = name;
                this.openterminateemployee = true
                setTimeout(() => {
                    this.$modal.show('Terminate_Employee')
                }, 500)
            },
            CloseTerminateEmployee() {
                this.openterminateemployee = false
                setTimeout(() => {
                    this.$modal.hide('Terminate_Employee')
                }, 500)
            },
            openTerminateEmployeeWarningModal(message) {
                this.terminate_error_message = message
                this.open_terminate_employee_warning = true
                setTimeout(() => {
                    this.$modal.show('Terminate_Employee_Warning')
                }, 500)
            },
            CloseTerminateEmployeeWarning() {
                this.open_terminate_employee_warning = false
                setTimeout(() => {
                    this.$modal.hide('Terminate_Employee_Warning')
                }, 500)
            },
            terminateEmployeeSuccess(id) {
                this.terminateEmpl(id);
            },
            redirectCreateEmployee(){
                this.$router.push({
                    path: "/employee/createemployee/cmpid/"+this.$route.params.company_id
                })
            },
            updateSuccess() {
                this.getEmployeesData();
            },
            previousPage() {
                this.temp_total = 0
                this.skip_temp = 0
                if (this.skip == 0) {
                    return;
                }
                this.skip = this.skip - this.limit <= 0 ? 0 : this.skip - this.limit;
                this.getEmployeesData();
            },
            nextPage() {
                this.skip_temp = this.skip + this.limit > this.total ? this.total : this.skip + this.limit;
                if (this.skip_temp >= this.total) {
                    return;
                }
                this.skip = this.skip_temp;
                this.getEmployeesData();
            },
            firstPage(){
                this.temp_total = 0
                this.skip = 0
                this.limit = this.limit
                this.list_view_data = [] 
                this.getEmployeesData();
            },
            lastPage() {
                this.skip_temp = 0
                this.skip = 0
                this.temp_total = this.total
                let no_of_pages = Math.ceil(this.total/this.limit)
                this.skip_temp = (no_of_pages * this.limit) - this.limit
                if (this.skip_temp >= this.total) {
                    return;
                }
                this.skip = this.skip_temp
                this.getEmployeesData();
            },
            async assignToDifferent(type, id) {
                let params = {
                };
                if (type == 'super_admin') {
                    params.user_access = 'super_admin';
                } else if(type == 'admin') {
                    params.user_access = 'admin';
                } else if(type == 'employee') {
                    params.user_access = 'employee';
                }
                await this.$http.patch(globals.AUTH_SERVICE + `/employees/${id}`, params).then(response => {
                    if(response.data.status_id == 1) {
                        this.success_msg = response.data.message
                        this.$refs.success_modal.open();
                        setTimeout(() => {
                            this.$refs.success_modal.close();
                            this.getEmployeesData()
                        }, 2000);
                    }
                }).catch((err) => {
                    this.warning_msg = err.response ? err.response.data.reason : err;
                    this.$refs.warning_modal.open();
                });
            },
            getEmployeesDataOninput() {
                this.skip = 0
                this.getEmployeesData();
            },
            async getEmployeesData() {
                this.employees_data = []
                this.ajax_call_in_progress = true;
                try {
                    let params = {
                        skip: this.skip,
                        limit: this.limit,
                        search_key: this.search_key,
                        company_id: this.$route.params.company_id
                    };
                    let response = await this.getListEmployees(params);
                    if(response.status_id == 1) {
                        this.show_list_epmloyee = true;
                        this.employees_data = response.response;
                        this.total = response.count;
                    }
                    this.ajax_call_in_progress = false;
                }
                catch(err) {
                    // console.log(err);
                }
            },
            async getCompData() {
                this.ajax_call_in_progress = true;
                try {
                    let response = await this.getCompanyById(this.$route.params.company_id);
                    if (response.status_id == 1) {
                        let res_data = response.response
                        this.comp_data.company_name = res_data.name
                        this.comp_data.logo = res_data.logo
                        this.comp_data.cover_image = res_data.cover_image
                        localStorage.setItem('company_domain_name', res_data.domain);
                    }
                    this.ajax_call_in_progress = false;
                }
                catch(err) {
                    // console.log(err)
                }
            },
            goToEmployeeView(id, user_access) {
                this.$router.push(`/employee/viewemployee/cmpid/${this.$route.params.company_id}/${id}`);
            },
            async terminateEmpl(id) {
                try {
                    let response = await this.deleteEmployeeById(id);
                    if(response.status_id == 1) {
                        this.success_msg = response.message
                        this.$refs.success_modal.open();
                        setTimeout(() => {
                            this.$refs.success_modal.close();
                        }, 2000);
                        this.openterminateemployee = false
                        setTimeout(() => {
                            this.$modal.hide('Terminate_Employee')
                        }, 500)
                        this.getEmployeesData();
                    } else {
                        this.openTerminateEmployeeWarningModal(response.message)
                    }
                }
                catch(err) {
                    console.log(err)
                    this.openTerminateEmployeeWarningModal(err)
                    // console.log(err);
                }
            }
        },
        mounted() {
            // for (let i = 1; i <= 50; i++) {
            //     i += 9
            //     this.pageLimit.push(i);
            // }
            this.getEmployeesData();
        },
        created() {
            for (let i = 10; i <= 50; i+=10) {
                this.pageLimit.push(i);
            }
        },
        watch: {
            limit: function(){
                this.getEmployeesData()
            },
        },
        computed: {
            loggedInUser() {
                return this.$store.getters.loggedInUser;
            },
            getSkipCount(){
                if(this.total == 0) {
                    return 0
                } else {
                    let skip_count = this.skip>this.total?this.total:this.skip+1;
                    return skip_count;
                }
            },
        },
        components: {
            TerminateEmployeeModal,
            HalfCircleSpinner,
            SweetModal,
            TerminateEmployeeWarningModal,
            suspendPopUp
        }
    })
</script>

<style scoped>

    .cover-pic img {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        vertical-align: middle;
        border-style: none;
    }

    a:not([href]):not([tabindex]) {
        color: inherit;
        text-decoration: none;
    }

    .btn-new-follow {
        color: #000 !important;
        background-color: #ffcb73;
        box-shadow: none !important;
        padding-left: 50px !important;
        padding-right: 50px !important;
        font-weight: 500;
        border-radius: 5px;
        border-color: 2px solid #ffcb73;
    }

    .btn-new-connected {
        color: #fff !important;
        background-color: #2cc36a;
        box-shadow: none !important;
        font-weight: 500;
        border-radius: 5px;
        border-color: 2px solid #2cc36a;
        padding-left: 50px !important;
        padding-right: 50px !important;
    }

    .add_access_input {
        border-bottom: 1px solid grey;
    }

    .add_access_input:focus {
        border-bottom: 1px solid #0d84f2;
    }

    .list-group-item {
        border-bottom: 1px solid #cce3fc;
    }

    .pl-5px {
        padding-left: 5px;
    }

    .pr-5px {
        padding-left: 5px;
    }

    .list-item-content {
        padding-left: 10px;
    }

    .dropdown-profiles {
        left: -75px !important;
        top: 10px !important;
    }

    .header-slant-tab{
        padding-top:12px;
    }
    .product-access-container {
        background-color: #fff;
        padding: 15.5px 0 0px 0;
        border-radius: 6px;
    }

    .create-company-header {
        padding: 9.9px 15px;
        background-color: #00448b;
    }

    .profile_button_super_admin {
        cursor: pointer;
        background-color: #ff268a;
        padding: 10px;
        border-radius: 5px;
        color: #fff;
        outline: none;
    }

    .profile_button_admin {
        cursor: pointer;
        background-color: #0d84f2;
        padding: 10px;
        border-radius: 5px;
        color: #fff;
        outline: none;
    }

    .profile_button_employee {
        cursor: pointer;
        background-color: #005dae;
        padding: 10px;
        border-radius: 5px;
        color: #fff;
        outline: none;
    }

    .profile_pic_super_admin {
        max-width: 100%;
        height: 135px !important;
        border-radius: 50%;
        border: 5px solid transparent;
        outline: 2px solid orange;
    }

    .profile_pic_admin {
        max-width: 100%;
        height: 135px !important;
        border-radius: 50%;
        border: 5px solid transparent;
        outline: 2px solid #1a9347;
    }

    .profile_pic_employee {
        max-width: 100%;
        height: 135px !important;
        border-radius: 50%;
        border: 5px solid transparent;
        outline: 2px solid #1a9347;
    }

    .w-280px {
        max-width: 265px;
        min-width: 140px;
    }

    .resend-btn {
        cursor: pointer;
        padding: 10px;
        background-color: #128828;
        color: #fff;
        width: 100%;
        border-radius: 5px;
        outline: none;
    }

    .dotted_line {
        position: relative;
        float: right;
        top: 15px;
        right: 10px;
    }

    .user_details {
        position: relative;
        top: 15px;
    }

    .e-tab {
        display: flex;
        justify-content: space-between;
    }

    @media screen and (max-width:1200px) {
        .e-tab {
            flex-direction: column;
        }
    }

    @media screen and (max-width:991.5px) {
        .e-tab {
            flex-direction: row;
        }
    }

    .el-button--primary {
        color: #fff;
        background-color: transparent;
        border-color: transparent;
    }
    .el-button{
        padding:0px;
    }

    .el-dropdown-menu__item:hover{
        background-color: #cce3fc !important;
        color: #0d5be6 !important;
    }
    .email-ellipses {
        margin-left: 20px;
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .not-allowed{
        cursor: no-drop !important;
    }
    .disabled:disabled{
        cursor: no-drop !important;
    }
</style>